// @file Helper for human readable number conversion
// Copied from
// https://www.npmjs.com/package/number_helpers
const number_with_delimiter = function (float, opts) {
  var _delimiter, _separator, decimal, integer, number, ref, ref1, ref2, rgx
  if (opts == null) {
    opts = {}
  }
  _separator = (ref = opts.separator) != null ? ref : '.'
  _delimiter = (ref1 = opts.delimiter) != null ? ref1 : ','
  number = float.toString().split('.')
  integer = number[0]
  decimal = (ref2 = number[1]) != null ? ref2 : ''
  if (!decimal) {
    _separator = ''
  }
  rgx = /(\d+)(\d{3})/
  if (_delimiter) {
    while (rgx.test(integer)) {
      integer = integer.replace(rgx, '$1' + _delimiter + '$2')
    }
  }
  return '' + integer + _separator + decimal
}

const safe_round = function (float, _significant, _precision) {
  var _round, value
  if (!float) {
    return float
  }
  value = (+float).toExponential().toString().split('e')
  _round = _significant ? -1 - value[1] + _precision : _precision
  value = Math.round(+(value[0] + 'e' + (+value[1] + _round)))
  value = value.toExponential().toString().split('e')
  return (value = +(value[0] + 'e' + (+value[1] - _round)))
}

const number_with_precision = function (float, opts) {
  var _delimiter,
    _precision,
    _separator,
    _significant,
    _skip_empty_fractionals,
    _strip_insignificant_zeros,
    decimal,
    dlen,
    i,
    integer,
    multiple,
    newlen,
    num_array,
    num_lngth,
    number,
    ref,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    rnd,
    rounded,
    significant,
    sigs,
    zcount
  if (opts == null) {
    opts = {}
  }
  _precision = (ref = opts.precision) != null ? ref : 3
  _delimiter = (ref1 = opts.delimiter) != null ? ref1 : ','
  _separator = (ref2 = opts.separator) != null ? ref2 : '.'
  _significant = (ref3 = opts.significant) != null ? ref3 : false
  _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false
  _skip_empty_fractionals = opts.strip_empty_fractional_parts
  number = float.toString().split('.')
  integer = number[0]
  decimal = (ref5 = number[1]) != null ? ref5 : ''
  if (_significant) {
    rnd = _precision - integer.length
  } else {
    rnd = _precision
  }
  if (rnd < 1) {
    rnd = 0
  }
  multiple = Math.pow(10, rnd)
  if (multiple > 1) {
    rounded = safe_round(float, _significant, _precision)
  } else {
    rounded = float
  }
  number = rounded.toString().split('.')
  integer = number[0]
  decimal = (ref6 = number[1]) != null ? ref6 : ''
  decimal = parseFloat('0.' + decimal).toFixed(_precision)
  decimal = decimal.toString().split('.')
  decimal = (ref7 = decimal[1]) != null ? ref7 : ''
  number = (integer + '.' + decimal) * 1
  num_array = number.toString().split('')
  num_lngth = num_array.length
  i = 0
  sigs = 0
  while (i < num_lngth) {
    if (!(num_array[i] === '.' || num_array[i] === '0')) {
      sigs++
    }
    i++
  }
  if (_significant && sigs >= _precision && _precision > 0) {
    significant = number.toPrecision(_precision) * 1
    significant = significant.toString().split('.')
    integer = significant[0]
    decimal = (ref8 = significant[1]) != null ? ref8 : ''
  }
  integer = number_with_delimiter(integer, {
    delimiter: _delimiter,
  })
  if (_strip_insignificant_zeros) {
    dlen = decimal.length
    newlen = dlen
    while (newlen > 0 && decimal[newlen - 1] === '0') {
      newlen = newlen - 1
    }
    if (newlen === 0) {
      decimal = ''
    } else if (newlen !== dlen) {
      decimal = decimal.slice(0, newlen)
    }
  }
  if (_skip_empty_fractionals) {
    i = 0
    zcount = 0
    num_array = decimal.split('')
    dlen = decimal.length
    while (i < dlen) {
      if (num_array[i] === '0') {
        zcount++
      }
      i++
    }
    if (zcount === dlen) {
      decimal = ''
    }
  }
  if (!decimal) {
    _separator = ''
  }
  return '' + integer + _separator + decimal
}

export const numberToHumanSize = function (float, opts = {}) {
  var _delimiter,
    _precision,
    _separator,
    _significant,
    _strip_insignificant_zeros,
    abs_float,
    denom,
    label,
    number,
    precise,
    ref,
    ref1,
    ref2,
    ref3,
    ref4
  _precision = (ref = opts.precision) != null ? ref : 0
  _separator = (ref1 = opts.separator) != null ? ref1 : '.'
  _significant = (ref2 = opts.significant) != null ? ref2 : true
  _delimiter = (ref3 = opts.delimiter) != null ? ref3 : ','
  _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false
  if (float > 1000) {
    // Power of 10 to Bytes Converter
    float = float / 1.024
  }
  if (float > 1000000) {
    float = float / 1.024
  }
  if (float > 1000000000) {
    float = float / 1.024
  }
  if (float > 1000000000000) {
    float = float / 1.024
  }
  // Remove the sign of the number for easier comparision
  abs_float = Math.abs(float)
  // Less than Thousand does not need text or a insignifiant digits
  if (abs_float < Math.pow(10, 3)) {
    denom = 1
    label = 'Bytes'
  } else if (abs_float >= Math.pow(10, 3) && abs_float < Math.pow(10, 6)) {
    denom = Math.pow(10, 3)
    label = 'KB'
  } else if (abs_float >= Math.pow(10, 6) && abs_float < Math.pow(10, 9)) {
    denom = Math.pow(10, 6)
    label = 'MB'
  } else if (abs_float >= Math.pow(10, 9) && abs_float < Math.pow(10, 12)) {
    denom = Math.pow(10, 9)
    label = 'GB'
  } else if (abs_float >= Math.pow(10, 12) && abs_float < Math.pow(10, 15)) {
    denom = Math.pow(10, 12)
    label = 'TB'
  }
  // Process the number into a presentable format
  number = float / denom
  precise = number_with_precision(number, {
    precision: _precision,
    significant: _significant,
    delimiter: _delimiter,
    separator: _separator,
    strip_insignificant_zeros: label === 'Bytes' ? true : _strip_insignificant_zeros,
  })
  return `${precise}${label}`
}
